import React from "react";
import Container from 'react-bootstrap/Container';
import Seo from "../../Seo";

export default class About extends React.Component {
    render() {
        const sectionStyle = {
            backgroundImage: "url('https://assets.tangentindia.org/assets/newsletter-banner.jpeg')"
        };
        return (
            <div className="about">
                <Seo title='About Tangent India | Tangent India'
                     description='Tangent India is a platform for past members of Ladies Circle to stay connected and continue the bonds of friendship that were formed in their circling years.'
                />
                {/*<div id="about-header-background" style={sectionStyle}>*/}
                {/*    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'*/}
                {/*         className="hidden header-foreground" alt=""/>*/}
                {/*</div>*/}
                <div className="title center">
                    <h1>
                        About Tangent India
                    </h1>
                </div>
                <div className="section">
                    <Container>
                        <p>
                            Tangent Clubs began in 1950 in UK. The past members of Ladies Circle wanted to stay
                            connected and continue the bonds of friendship that were formed in their circling years.
                            Thus the first Tangent Club was founded in UK and soon enough other nations followed suit.
                        </p>
                        <p>
                            In course of time, the past circlers in India too felt the need for an organised and formal
                            platform to continue the bonds of friendship formed in Ladies Circle. In the year 2006,
                            under the dynamic guidance of 41er Sameer Nayyar, the then President, <a
                            href='https://www.41clubsofindia.org/' rel="noreferrer" target='_blank'>Association of 41
                            Clubs of India</a>, the first Tangent Club was started in Mumbai. Jogesh Mittal from Mumbai
                            took on the mantle of the Charter President of TANGENT INDIA. Tangent Clubs in India include
                            41ers wives, past Circlers and ex-Tabler’s wives.
                        </p>
                        <p>
                            Since its days of infancy, TANGENT INDIA has grown from strength to strength. With able
                            leadership, each year, progress was made by laying down procedures and increasing
                            membership. In 2018, at the international AGM in Austria, TANGENT INDIA became a full
                            member country of <a href='http://www.tangentclubinternational.org/' rel="noreferrer"
                                                 target='_blank'>Tangent Club International</a>. Tangent India is now a
                            Registered Society under the Tamil Nadu Societies Registration Act, 1975.
                        </p>
                        <p>
                            The motto of TANGENT INDIA is FRIENDSHIP CONTINUED FOREVER.
                        </p>

                        Click <a href='https://assets.tangentindia.org/assets/constitution-2024.pdf' target='_blank'
                                 rel='noreferrer'>here to view the Tangent India Constitution and
                        Bye-Laws</a>.
                    </Container>
                </div>
                {/*<div className="grey-bg section">*/}
                {/*    <h2 className="center">History of Tangent India</h2>*/}
                {/*    <Timeline/>*/}
                {/*</div>*/}
            </div>
        );
    }
}