import React from "react";
import Container from "react-bootstrap/Container";
import Seo from "../../Seo";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Tab} from "react-bootstrap";
import {Tabs} from "react-bootstrap";

function Business() {
    const sectionStyle = {
        backgroundImage: "url('https://assets.tangentindia.org/assets/home_banner_back.webp')"
    };
    return (
        <div id="documents" style={{backgroundColor: '#faf193'}}>
            <Seo title='Business | Tangent India'
                 description="Tangent India Business - Tangent Business Network helps members of Tangent India connect with each other."
            />
            {/*<div id="home-header-background" style={sectionStyle}>*/}
            {/*    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'*/}
            {/*         className="hidden header-foreground" alt=""/>*/}
            {/*</div>*/}
            <div className="center title">
                <img src={'https://assets.tangentindia.org/business/tbni.png'} width={'10%'}/>
                <h1>Tangent Business Networking India</h1>
            </div>
            <Container>
                <p>
                    The TBNI (Tangent Business Networking India) initiative provides a unique opportunity to all Tangent India members to network with like-minded women in our association.
                </p>
                <Row>
                    <Col md={3}>
                        <img src={'https://assets.tangentindia.org/business/tbni.png'} width={'100%'}/>
                    </Col>
                    <Col md={9}>
                        <p>
                            <strong>Mission</strong>: To bring together a dynamic group of women currently in business or aspiring to start their own business, big or small, and create a forum to promote, enhance and exchange business ideas across the Tangent family.
                        </p>
                        <ul>
                            <li>This forum will provide a plethora of opportunities to members to network, learn, share, and help each other grow to be the Best Version of themselves!</li>
                            <li>Members will feature in the Tangent India Yellow Pages directory.</li>
                            <li>A specially designed TBNI pin will be given, which can be worn at Tangent/41ers events to identify yourself as part of this elite community.</li>
                            <li>Business forums will be organised through the year where experts will help you move further along your chosen path.</li>
                            <li>This will be an opportunity to showcase your product/service and network.</li>
                            <li>Your storyboards will be showcased via Facebook and the national newsletter for maximum exposure and encouragement to you and your enterprise.</li>
                        </ul>
                    </Col>
                </Row>
                <p>
                    This opportunity is open for existing and aspiring business owners – if you have an idea, a vision, a hope, a desire to learn – we are there to help you fructify that.  All this and more is available at a minimal annual contribution to join the network. Please click the link below to learn more about TBNI. You can reach out to the TBNI Convenor on tangentbni@gmail.com.
                </p>
                <p>
                    Let us all help each other along the journey to excellence with TBNI
                </p>

                <Row className={'center'}>
                    <Col md>
                        <a href='https://assets.tangentindia.org/business/tbni.pdf'>TBNI Brochure</a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Business;

