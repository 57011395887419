import React from "react";
import Seo from "../../Seo";
import Container from "react-bootstrap/Container";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleRight} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Carousel from 'react-bootstrap/Carousel';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


export default class Extension extends React.Component {
    render() {
        const sectionStyleMessage = {
            backgroundImage: "url('https://assets.tangentindia.org/extension/extension-message.png')",
            backgroundSize: "contain"
        };
        let carouselImages = [
            '/6/1.jpeg',
            // '/6/2.jpeg',
            '/6/3.jpeg',
            // '/6/4.jpeg',
            // '/6/5.jpeg',
            '/47/1.jpeg',
            '/47/2.jpeg',
            '/47/3.jpeg',
            // '/48/1.jpeg',
            // '/48/2.jpeg',
            // '/48/3.jpeg',
            '/49/1.jpeg',
            '/49/2.jpeg',
            '/49/3.jpeg',
            '/50/1.jpeg',
            '/50/2.jpeg',
            '/50/3.jpeg',
            '/51/1.jpeg',
            '/51/2.jpeg',
            '/51/3.jpeg',
            '/51/4.jpeg',
            '/51/5.jpeg',
            '/51/6.jpeg',
            // '/52/1.jpeg',
            '/52/2.jpeg',
            '/52/3.jpeg',
            // '/52/4.jpeg',
            // '/53/1.jpeg',
            '/53/2.jpeg',
            '/53/3.jpeg',
            '/53/4.jpeg',
            '/53/5.jpeg',
            '/54/1.jpeg',
            '/54/2.jpeg',
            '/54/3.jpeg',
            // '/55/1.jpeg',
            '/55/2.jpeg',
            // '/55/3.jpeg',
        ]

        const carouselStyle = {
            height: '400px', // You can adjust this value based on your requirements
            overflow: 'hidden', // Hide anything that exceeds the height
        };

        const imgStyle = {
            height: '100%',
            width: 'auto',
            maxWidth: '100%', // Ensure that the image doesn't exceed the container width
            objectFit: 'cover', // Resize the image to cover the carousel while maintaining its aspect ratio
            objectPosition: 'center center', // Center the image both vertically and horizontally within the carousel
        };

        return (
            <div id="membership">
                <Seo title='Extensions | Tangent India'
                     description="Extension Information about Tangent India's Extension Policies. Start a new club, induct a new member, build your community."
                />

                {/*<Carousel style={carouselStyle}>*/}
                {/*    {carouselImages.map(path => {*/}
                {/*        return (*/}
                {/*            <Carousel.Item interval={1500}>*/}
                {/*                <img*/}
                {/*                    style={imgStyle}*/}
                {/*                    className="d-block w-100"*/}
                {/*                    src={"https://assets.tangentindia.org/extension/carousel" + path}*/}
                {/*                    alt="Image of Charter of a Tangent India Club"*/}
                {/*                />*/}
                {/*                /!*<Carousel.Caption>*!/*/}
                {/*                /!*    <h3>Label for first slide</h3>*!/*/}
                {/*                /!*    <p>{path}</p>*!/*/}
                {/*                /!*</Carousel.Caption>*!/*/}
                {/*            </Carousel.Item>*/}
                {/*        )*/}
                {/*    })}*/}
                {/*</Carousel>*/}

                {/*<Carousel>*/}
                {/*    {carouselImages.map(path => {*/}
                {/*        return (*/}
                {/*            <Carousel.Item interval={1500}>*/}
                {/*                <img*/}
                {/*                    height=*/}
                {/*                    className="d-block w-100"*/}
                {/*                    src={"https://assets.tangentindia.org/extension/carousel" + path}*/}
                {/*                    alt="Image of Charter of a Tangent India Club"*/}
                {/*                />*/}
                {/*                <Carousel.Caption>*/}
                {/*                    <h3>Label for first slide</h3>*/}
                {/*                    <p>{path}</p>*/}
                {/*                </Carousel.Caption>*/}
                {/*            </Carousel.Item>*/}
                {/*        )*/}
                {/*    })}*/}
                {/*</Carousel>*/}
                <div className="title center">
                    <h1>
                        Tangent India Extensions
                    </h1>
                </div>
                {/*<div id="home-header-background" style={sectionStyleMessage}>*/}
                {/*    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'*/}
                {/*         className="hidden header-foreground" alt=""/>*/}
                {/*</div>*/}
                {/*<div className={'text-center'}>*/}
                {/*    <img src='https://assets.tangentindia.org/extension/extension-message.png'*/}
                {/*         className="text-center" width={'80%'} alt=""/>*/}
                {/*</div>*/}
                <div style={{height: '25px'}} />
                <Container>
                    <Tabs
                        defaultActiveKey="extensionKit"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="extensionKit" title="Extension Kit">
                            <Container className="section">
                                <h2>Extension Kit</h2>
                                <Row>
                                    <div>
                                        <a href='https://assets.tangentindia.org/extension/Letter%20to%20Prospective%20Tangent%20Members.pdf' target='_blank'>Click here to view the Letter to Prospective Tangent Members.</a>
                                    </div>
                                    <div>
                                        <a href='https://assets.tangentindia.org/extension/Welcome Note.pdf' target='_blank'>Click here to view the Welcome Note.</a>
                                    </div>
                                    <div className="video-container" style={{position: "relative", overflow: "hidden", width: "100%", paddingTop: "56.25%"}}>
                                        <iframe className="responsive-iframe" style={{position: "absolute", top: 0, left: 0, bottom: 0, right: 0, width: "100%", height: "100%"}}
                                                src="https://assets.tangentindia.org/extension/Welcome Video.mp4"></iframe>
                                    </div>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="charterProcedure" title="Charter Procedure">
                            <Container className="section">
                                <h2>Charter Procedure</h2>

                                <ul className="fa-ul">
                                    {
                                        [
                                            "A new Tangent Club may be sponsored either by an existing Tangent club, preferably in the region or Area Chairman of the 41 Clubs, or the Chairman of the local 41 Club.",
                                            "A minimum of 7 members are required to form a club, from whom the chairman and secretary will be nominated.",
                                            "The prospective members must have 3 contact meetings before the club can be deemed ready for charter.",
                                            "The sponsoring authority is required to send a report of these meetings to the Tangent President and all the Board members.",
                                            "The roles of the Office Bearers of the club, i.e. Chairperson and Secretary will be intimated by the Tangent National Secretary to the prospective members.",
                                            "The sponsoring authority will submit an application for Charter to the President of Tangent India, which will be accompanied by a bio-data of the prospective members (complete details) along with a Demand Draft for the TangentTimes Fee of the prospective members, such application and monies to reach the Treasurer of Tangent India at least 30 days before the date of Charter.",
                                            "The Treasurer of Tangent India will send the details for money transfer/RTGS/draft to the prospective members.",
                                            "The Treasurer will keep a record of the chartered clubs and the number of members. She will send the Chairperson’s jewel, the pins for Chairperson and Secretary, the Directory and the receipts for the money received.",
                                            "The Charter will be presented to the new Club by any of the current Tangent Board members or a past President or any past Board member. In the absence of any such Tangent present or past Board member being present, the Charter may be presented by the current or past President of 41 Clubs of India. The Charter certificate shall be sent by the President of Tangent India.",
                                        ].map((item, idx) => {
                                            return (
                                                <li key={idx} className="small-objective">
                                                    <FontAwesomeIcon icon={faArrowCircleRight} listItem/>
                                                    {item}
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </Container>
                        </Tab>
                        <Tab eventKey="charterAgenda" title="Charter Agenda">
                            <Container className="section">
                                <h2>Charter Agenda</h2>

                                <ul className="fa-ul">
                                    {
                                        [
                                            "MC welcomes all to the Charter",
                                            "MC raises toast to the President of India",
                                            "MC asks all to rise for “TANGENT” prayer",
                                            "MC introduces the Chairperson elect",
                                            "Chairperson welcomes Chief Guest, guests and others",
                                            "Chairperson reads out Tangent President’s speech (if she is not present)",
                                            "Chairperson introduces Chief Guest and requests her/him to do the honors",
                                            "Chief Guest’s Speech",
                                            "Chief Guest reads the Charter Certificate, then hands it over to the Chairperson",
                                            "Chief Guest bestows the jewel and pin on the Chairperson and also hands over the President’s banner, the Directory, etc",
                                            "The Chief Guest then pins the Secretary and also hands over the President’s banner, the Directory, etc",
                                            "This is followed by the induction of the remaining members, similarly",
                                            "MC asks new Chairperson to speak",
                                            "Chairperson introduces the Office Bearers and members of the new Club to the gathering",
                                            "Chairperson then reads the greetings/messages received",
                                            "Secretary of the new Club gives the Vote of Thanks and makes club announcements – the next meeting date, etc",
                                            <a href='https://assets.tangentindia.org/assets/new-member-oath.jpeg'
                                               target='_blank' rel='noreferrer'>
                                                Click here to view the Tangent India New Member Oath.
                                            </a>,
                                            "The Chairperson then raises a Toast to TANGENT India and 41 CLUBS OF INDIA",
                                            "NATIONAL ANTHEM",
                                        ].map((item, idx) => {
                                            return (
                                                <li key={idx} className="small-objective">
                                                    <FontAwesomeIcon icon={faArrowCircleRight} listItem/>
                                                    {item}
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                                *MC may be appointed by the Sponsoring authority and either be a member of the sponsoring
                                Tangent
                                Club, 41 Club or any other member of TANGENT India or 41 Clubs of India.
                            </Container>
                        </Tab>
                    </Tabs>
                </Container>
            </div>
        );
    }
}