import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleRight} from "@fortawesome/free-solid-svg-icons";
import Seo from "../../Seo";

function Home() {
    const sectionStyle = {
        backgroundColor: "#e7e7e5"
    };
    return (
        <div id="home">
            <Seo title='Tangent India - Home'/>
            <div id="home-header-background" style={sectionStyle}>
                <img src='https://assets.tangentindia.org/assets/2024-logo.png' className="header-foreground"
                     alt=""/>
            </div>
            <Container>
                <Row>
                    <h2 className={'center'}>Welcome to Tangent India</h2>
                </Row>
                <Row>
                    <Col md className="d-flex align-items-center">
                        <div>
                        <p>
                            Tangent India is a part of the Round Table India family and a full member of Tangent Club International. We turned 18 this year, and have 56 clubs across India, with over 850 members. Our members are wives of past Round Tablers.
                        </p>
                        <p>
                            As a community, we are committed to deepening the friendships we've forged over the years through Tabling & Circling, celebrating the values of fun, fellowship, and service to others. We engage in a diverse array of community service initiatives at both the national and club levels. Our long-term national projects, Crimson and Drishyam, are dedicated to advancing women’s hygiene and restoring the gift of sight, respectively.
                        </p>
                        </div>
                    </Col>
                    <Col md className={'center'}>
                        <img src={'https://assets.tangentindia.org/team/2024/nex-2024.jpeg'} width={'80%'}/>
                    </Col>
                </Row>
                <Row>
                    <Col md>
                        <h2>Prayer</h2>
                        <p>For all thy gifts we thank thee Lord especially for the gift of continued friendship.</p>
                        <h2>Motto</h2>
                        <p>Friendship Continued Forever</p>
                    </Col>
                    <Col md>
                        <h2>Aims & Objectives</h2>
                        <ul>
                            <li>To continue the friendships formed in Ladies Circle and Round Table</li>
                            <li>To work side by side with Round Table India, Ladies Circle India & 41 Clubs of India</li>
                            <li>To share and enjoy activities based on fun and fellowship locally and globally</li>
                            <li>To reach out to the needy and the deserving wherever possible</li>
                            <li>To be non-sectarian and non-political</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <h2 className={'center'}>Partners</h2>
                </Row>
                <Row>
                    <Col md className={'center'}><img src={'https://assets.tangentindia.org/assets/partners/41-logo.jpg'} width={'50%'} /></Col>
                    <Col md className={'center'}><img src={'https://assets.tangentindia.org/assets/partners/rti-logo.png'} width={'50%'} /></Col>
                    <Col md className={'center'}><img src={'https://assets.tangentindia.org/assets/partners/lci-logo.png'} width={'50%'} /></Col>
                </Row>
                <Row className={'center'}>
                    <Col md>
                        <p>Member of the Round Table India Family.</p>
                    </Col>
                    <Col md>
                        <p>Member of the Tangent Club International.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Home;