import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Seo from "../../Seo";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {Document, Page, pdfjs} from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class Directory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1
        }
        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
    }

    changeState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    prevPage() {
        this.setState({
            ...this.state,
            pageNum: this.state.pageNum - 1
        })
    }

    nextPage() {
        this.setState({
            ...this.state,
            pageNum: this.state.pageNum + 1
        })
    }

    render() {
        const sectionStyle = {
            backgroundImage: "url('https://assets.tangentindia.org/assets/membership-banner.webp')"
        };
        return (
            <div id="annual-administrative-fee-form">
                <Seo title='Member Directory | Tangent India'
                     description='Directory of Tangent India Members.'
                     noindex={true}
                />
                {/*<div id="documents-header-background" style={sectionStyle}>*/}
                {/*    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'*/}
                {/*         className="hidden header-foreground" alt=""/>*/}
                {/*</div>*/}
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin">Admin</Breadcrumb.Item>
                    <Breadcrumb.Item active>Tangent Directory</Breadcrumb.Item>
                </Breadcrumb>
                <div className="center title">
                    <h1>Tangent Directory</h1>
                </div>
                <div className="section">
                    <Container className='directory-container'>
                        <Row>
                            <Col>
                                <Document
                                    file='https://assets.tangentindia.org/assets/directory.pdf'
                                    onContextMenu={(e) => e.preventDefault()}>
                                    <Page pageNumber={this.state.pageNum}/>
                                </Document>
                                {this.state.pageNum > 1 ? <Button onClick={this.prevPage}>Previous Page</Button> :
                                    <Button disabled>Previous Page</Button>}
                                {this.state.pageNum < 14 ? <Button onClick={this.nextPage}>Next Page</Button> :
                                    <Button disabled>Next Page</Button>}
                            </Col>
                            {/*<Col>*/}
                            {/*    <Document*/}
                            {/*        file={officeBearers}*/}
                            {/*        onContextMenu={(e) => e.preventDefault()}>*/}
                            {/*        <Page pageNumber={1} />*/}
                            {/*    </Document>*/}
                            {/*</Col>*/}
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}
