import React from "react";
import Container from "react-bootstrap/Container";
import Seo from "../../Seo";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Tab} from "react-bootstrap";
import {Tabs} from "react-bootstrap";

function Projects() {
    const sectionStyle = {
        backgroundImage: "url('https://assets.tangentindia.org/assets/home_banner_back.webp')"
    };
    return (
        <div id="documents">
            <Seo title='Projects | Tangent India'
                 description="Tangent India Projects - Projects are an integral part of Tangent India."
            />
            {/*<div id="home-header-background" style={sectionStyle}>*/}
            {/*    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'*/}
            {/*         className="hidden header-foreground" alt=""/>*/}
            {/*</div>*/}
            <div className="center title">
                <h1>Projects</h1>
            </div>
            <Container>
                <p>
                    Projects have always an integral part of our lives as members of the Round Table family. As you know, community service has been and continues to be one of the fundamentals of our association. This year, at the National Level, we will continue to focus on women's hygiene and providing the gift of sight, with some advancements in both areas.  An overview of the National Projects is given below. Please click on the individual links to learn more and apply for these projects.
                </p>
                <Tabs
                    defaultActiveKey="drishyam"
                    className="mb-3"
                >
                    <Tab eventKey="drishyam" title="Drishyam">
                        <p>
                            The Drishyam project aims at providing complete eye care services (diagnosis & vision correction for identified patients, including surgery if required.) Tangent India will facilitate the conduct of Eye Camps for both children and adults. We have a partnership with Optometry Confederation of India and Zeiss (Aloka Vision) for this project. We have helped enabled over 5000 people in the past few years through this project.
                            The project convenor can be contacted on drishyamtangentindia@gmail.com for more information.
                        </p>
                        <Row className={'center'}>
                            <Col md>
                                <a href='https://assets.tangentindia.org/projects/drishyam.pdf'>Drishyam PDF</a>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="jyoti-drishyam" title="Jyoti Drishyam">
                        <p>
                            The Jyoti Drishyam project aims at giving the gift of AI vision to visually impaired beneficiaries. This project is being launched this year as a result of a tie up with Torchit Foundation, Ahmedabad. Each shortlisted beneficiary will be given a Jyoti AI Pro Kit that contains glasses, AI Camera, Reading Stand and a ring.
                            The project convenor can be contacted on jyotidrishyam.tangentindia@gmail.com for more information.
                        </p>
                        <Row className={'center'}>
                            <Col md>
                                <a href='https://assets.tangentindia.org/projects/jyoti-drishyam.pdf'>Jyoti Drishyam PDF</a>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="crimson" title="Crimson and Crimson Plus">
                        <p>
                            The CRIMSON and CRIMSON PLUS projects represent Tangent India’s commitment towards improved hygiene in ladies of low-income groups in India. These are long term projects of Tangent India, supported by the Association of 41 Clubs of India, RTI Foundation, as well as donors from different walks of life.
                        </p>
                        <p>
                            The CRIMSON project focuses on advocating the use of bio-degradable sanitary napkins. Through this project, Tangent India aims to educate women on how to maintain personal hygiene and facilitate the same by providing bio-degradable sanitary napkins to the intended users.
                        </p>
                        <p>
                            The CRIMSON PLUS project being launched this year by Tangent India, focuses on a much larger audience and aims to educate women of all ages on the use of reusable pads to help in menopausal spotting, urine incontinence, and during the menstruation cycle.
                        </p>
                        <p>
                            Please read the PDF & watch the video to learn more about the projects and your Tangent Club can apply for these projects. You can also write to this year's Crimson Project Convenors: Aarti Shroff and Archana Dange on crimsonandplus.ti@gmail.com.
                        </p>
                        <Row className={'center'}>
                            <Col md>
                                <a href='https://assets.tangentindia.org/projects/crimson.pdf'>Crimson and Crimson Plus PDF</a>
                            </Col>
                            <Col md>
                                <a href='https://assets.tangentindia.org/projects/crimson-video.mp4'>Crimson and Crimson Plus Video</a>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </Container>
        </div>
    );
}

export default Projects;

