import React from "react";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Seo from "../../Seo";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReCAPTCHA from 'react-google-recaptcha';
import Spinner from "react-bootstrap/Spinner";

export default class NewMembership extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false
        }
        this.changeState = this.changeState.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeRecaptcha = this.changeRecaptcha.bind(this);
        this.changeFile = this.changeFile.bind(this);
    }

    changeState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    changeRecaptcha(e) {
        this.setState({
            ...this.state,
            recaptcha: e
        })
    }

    handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();

        this.setState({
            validated: true
        })

        if (form.checkValidity() === false || !this.state.recaptcha) {
            event.stopPropagation();
            return;
        }

        this.setState({
            loading: true
        })

        fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/upload-photo?fileName=' + this.state.file.lastModified + '/' + this.state.file.name)
            .then(response => response.text())
            .then(response => {
                var uploadUrl = response;
                fetch(uploadUrl, {
                    method: 'PUT',
                    headers: new Headers({ 'Content-Type': this.state.file.type }),
                    body: this.state.file
                })
                    .then(response => response.text())
                    .then(photoUploadResponse => {
                        const pictureUrl = 'https://tangentindia-file-uploads.s3.ap-south-1.amazonaws.com/photos/' + this.state.file.lastModified + '/' + this.state.file.name;

                        fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/newMemberForm', {
                            method: 'POST',
                            body: JSON.stringify({...this.state, imageUrl: pictureUrl})
                        })
                            .then(response => response.json())
                            .then(response => {
                                this.setState({
                                    ...this.state,
                                    loading: false,
                                    alert: true,
                                    alertMessage: response['message'],
                                    alertType: response['type'],
                                });
                            })

                    })
            })




        //
        // this.setState({
        //     loading: true
        // })
        //
        // fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/newMemberForm', {
        //     method: 'POST',
        //     body: JSON.stringify({...this.state})
        // })
        //     .then(response => response.json())
        //     .then(response => {
        //         this.setState({
        //             ...this.state,
        //             loading: false,
        //             alert: true,
        //             alertMessage: response['message'],
        //             alertType: response['type'],
        //         });
        //     })
    }

    changeFile(e) {
        this.setState({
            ...this.state,
            file: e.target.files[0]
        })
    }

    render() {
        const sectionStyle = {
            backgroundImage: "url('https://assets.tangentindia.org/assets/home_banner_back.webp')"
        };
        return (
            <div id="membership">
                <Seo title='New Membership Form | Tangent India'
                     description="New Membership Form for Tangent India."
                />
                {/*<div id="home-header-background" style={sectionStyle}>*/}
                {/*    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'*/}
                {/*         className="hidden header-foreground" alt=""/>*/}
                {/*</div>*/}
                <div className="title center">
                    <h1>
                        Tangent India New Membership Form
                    </h1>
                </div>
                <div>
                    <Container className="section">
                        {this.state.alertType && <Alert variant={this.state.alertType}>{this.state.alertMessage}</Alert>}
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <Row>
                                <Col sm>
                                    <Form.Group className="mb-3" controlId="formMemberName">
                                        <Form.Label>Name of Member</Form.Label>
                                        <Form.Control required type="input" placeholder="Enter name"
                                                      value={this.state.name}
                                                      name='name'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                                <Col sm>
                                    <Form.Group className="mb-3" controlId="formMemberEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control required type="email" placeholder="Enter email"
                                                      value={this.state.email}
                                                      name='email'
                                                      onChange={this.changeState}/>
                                        <Form.Text className="text-muted">
                                            We'll never share your email with anyone else.
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm>
                                    <Form.Group className="mb-3" controlId="formCityName">
                                        <Form.Label>Name of Town for which application is being submitted</Form.Label>
                                        <Form.Control required type="input" placeholder="Enter city name"
                                                      value={this.state.city}
                                                      name='city'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                                <Col sm>
                                    <Form.Group className="mb-3" controlId="formClubNum">
                                        <Form.Label>Club Number for which application is being submitted</Form.Label>
                                        <Form.Control required type="number" placeholder="Enter club number"
                                                      value={this.state.clubNum}
                                                      name='clubNum'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm>
                                    <Form.Group className="mb-3" controlId="formMobileNumber">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <Form.Control required type="number" placeholder="Enter mobile number"
                                                      value={this.state.mobile}
                                                      name='mobile'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                                <Col sm>
                                    <Form.Group className="mb-3" controlId="formPhoneNumber">
                                        <Form.Label>Landline Number with City Code</Form.Label>
                                        <Form.Control required type="number" placeholder="Enter phone number"
                                                      value={this.state.phone}
                                                      name='phone'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm>
                                    <Form.Group className="mb-3" controlId="formDob">
                                        <Form.Label>Date of Birth</Form.Label>
                                        <Form.Control required type="date"
                                                      placeholder="Enter date of birth" value={this.state.dob}
                                                      name='dob'
                                                      onChange={this.changeState}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm>
                                    <Form.Group className="mb-3" controlId="formDoa">
                                        <Form.Label>Date of Anniversary</Form.Label>
                                        <Form.Control type="date" placeholder="Enter date of anniversary"
                                                      value={this.state.doa}
                                                      name='doa'
                                                      onChange={this.changeState}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHomeAddress">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control required as="textarea" rows={3}
                                                      value={this.state.address}
                                                      name='address'
                                                      onChange={this.changeState}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formProfession">
                                        <Form.Label>Profession</Form.Label>
                                        <Form.Control required type="input" placeholder="Enter profession"
                                                      value={this.state.profession}
                                                      name='profession'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formOfficeAddress">
                                        <Form.Label>Office Name, Address & Telephone</Form.Label>
                                        <Form.Control required as="textarea" rows={3}
                                                      value={this.state.officeAddress}
                                                      name='officeAddress'
                                                      onChange={this.changeState}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formLciClubNo">
                                        <Form.Label>Were you a member of Ladies Circle India? If yes, Ladies Circle Club No.</Form.Label>
                                        <Form.Control type="input" placeholder="Enter ladies circle club no"
                                                      value={this.state.lciClubNo}
                                                      name='lciClubNo'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formLciPositionInfo">
                                        <Form.Label>If held any position at the Circle/National Level, what position(s) & which year(s)</Form.Label>
                                        <Form.Control type="input" placeholder="Enter what position & which year"
                                                      value={this.state.lciPositionInfo}
                                                      name='lciPositionInfo'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formLciPositionRetiredResignated">
                                        <Form.Label>Year of Retirement/Resignation from Ladies Circle India</Form.Label>
                                        <Form.Control type="input" placeholder="Enter retirement/resignation year"
                                                      value={this.state.lciPositionRetiredResignated}
                                                      name='lciPositionRetiredResignated'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formSpouseName">
                                        <Form.Label>Name of Spouse</Form.Label>
                                        <Form.Control type="input" placeholder="Enter name of spouse"
                                                      value={this.state.spouseName}
                                                      name='spouseName'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formSpouseName">
                                        <Form.Label>Date of Birth of Spouse</Form.Label>
                                        <Form.Control type="date" placeholder="Enter date of birth of spouse"
                                                      value={this.state.spouseDob}
                                                      name='spouseDob'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>
                                    <Form.Group className="mb-3" controlId="formSpouseEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control required type="email" placeholder="Enter email"
                                                      value={this.state.spouseEmail}
                                                      name='spouseEmail'
                                                      onChange={this.changeState}/>
                                        <Form.Text className="text-muted">
                                            We'll never share your email with anyone else.
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col sm>
                                    <Form.Group className="mb-3" controlId="formSpouseMobileNumber">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <Form.Control required type="number" placeholder="Enter mobile number"
                                                      value={this.state.spouseMobile}
                                                      name='spouseMobile'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formRtiClubNo">
                                        <Form.Label>Round Table Club No.</Form.Label>
                                        <Form.Control type="input" placeholder="Enter round table club no"
                                                      value={this.state.rtiClubNo}
                                                      name='rtiClubNo'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formRtiPositionInfo">
                                        <Form.Label>If any position was held at the Table/National level, what position(s) & which year(s)</Form.Label>
                                        <Form.Control type="input" placeholder="Enter what position & which year"
                                                      value={this.state.rtiPositionInfo}
                                                      name='rtiPositionInfo'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formRtiPositionRetiredResignated">
                                        <Form.Label>Year of Retirment/Resignation from Table</Form.Label>
                                        <Form.Control type="input" placeholder="Enter retirement/resignation year"
                                                      value={this.state.rtiPositionRetiredResignated}
                                                      name='rtiPositionRetiredResignated'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formFortyOneClubNo">
                                        <Form.Label>41 Club No.</Form.Label>
                                        <Form.Control type="input" placeholder="Enter 41 club no"
                                                      value={this.state.fortyOneClubNo}
                                                      name='fortyOneClubNo'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formFortyOnePositionInfo">
                                        <Form.Label>What Position & Which Year</Form.Label>
                                        <Form.Control type="input" placeholder="Enter what position & which year"
                                                      value={this.state.fortyOnePositionInfo}
                                                      name='fortyOnePositionInfo'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formFortyOnePositionRetiredResignated">
                                        <Form.Label>Retired/Resignated Position</Form.Label>
                                        <Form.Control type="input" placeholder="Enter retired resignation/position"
                                                      value={this.state.fortyOnePositionRetiredResignated}
                                                      name='fortyOnePositionRetiredResignated'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formSpouseProfession">
                                        <Form.Label>Profession</Form.Label>
                                        <Form.Control required type="input" placeholder="Enter profession"
                                                      value={this.state.spouseProfession}
                                                      name='spouseProfession'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formSpouseOfficeAddress">
                                        <Form.Label>Office Name, Address & Telephone</Form.Label>
                                        <Form.Control required as="textarea" rows={3}
                                                      value={this.state.spouseOfficeAddress}
                                                      name='spouseOfficeAddress'
                                                      onChange={this.changeState}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formChildrenCount">
                                        <Form.Label>Number of Children</Form.Label>
                                        <Form.Control required type="number" placeholder="Enter number of children"
                                                      value={this.state.childrenCount}
                                                      name='childrenCount'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formChildrenInfo">
                                        <Form.Label>Name & Age of Children</Form.Label>
                                        <Form.Control required as="textarea" rows={3}
                                                      value={this.state.childrenInfo}
                                                      name='childrenInfo'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formPhoto">
                                        <Form.Label>Please Upload a Passport Size Photo</Form.Label>
                                        <Form.Control type="file"
                                                      onChange={this.changeFile}
                                                      accept="image/*"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ReCAPTCHA
                                        sitekey="6LfibwIeAAAAALATkXIkl7-3mX7iOMExJW5sXHgd"
                                        onChange={this.changeRecaptcha}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {this.state.alertType ?
                                    <Alert variant={this.state.alertType}>{this.state.alertMessage}</Alert> : ''}
                                {this.state.loading && <Spinner animation="border"/>}
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div>
            </div>
        );
    }
}