import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Seo from "../../Seo";

function Downloads() {
    const sectionStyle = {
        backgroundImage: "url('https://assets.tangentindia.org/assets/newsletter-banner.jpeg')"
    };
    return (
        <div id="newsletter">
            <Seo title='Administrivia | Tangent India'
                 description="Tangent Administrivia and Forms for Administrative Purposes."
            />
            {/*<div id="newsletter-header-background" style={sectionStyle}>*/}
            {/*    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'*/}
            {/*         className="hidden header-foreground" alt=""/>*/}
            {/*</div>*/}
            <div className="center title">
                <h1>Administrivia</h1>
            </div>
            <div className="section">
                <Container>
                    <Row>
                        <Col>
                        <h2>Circulars</h2>
                        <ol>
                            <li>
                                <a href='https://assets.tangentindia.org/downloads/circulars/agm_reminder_letter.pdf' target='_blank' rel='noreferrer'>AGM Reminder Letter</a>
                            </li>
                            <li>
                                <a href='https://assets.tangentindia.org/downloads/circulars/agenda_club_agm.pdf' target='_blank' rel='noreferrer'>Agenda for Club AGM</a>
                            </li>
                            <li>
                                <a href='https://assets.tangentindia.org/downloads/circulars/annual_report_letter.pdf' target='_blank' rel='noreferrer'>Annual Report Letter</a>
                            </li>
                            <li>
                                <a href='https://forms.gle/KrSLWKJMMAkxiiU69' target='_blank' rel='noreferrer'>Annual Report</a>
                            </li>
                            <li>
                                <a href='https://assets.tangentindia.org/downloads/circulars/guidelines_board.pdf' target='_blank' rel='noreferrer'>Guidelines and Eligibility Criteria for Nominations to Tangent India Board Positions</a>
                            </li>
                            <li>
                                <a href='https://assets.tangentindia.org/downloads/circulars/nomination_board.pdf' target='_blank' rel='noreferrer'>Nomination Form for Tangent India Board Positions</a>
                            </li>
                            <li>
                                <a href='https://assets.tangentindia.org/downloads/circulars/tci_vp_guidelines.pdf' target='_blank' rel='noreferrer'>Guidelines and Eligibility Criteria for Nominations to TCI Vice President</a>
                            </li>
                            <li>
                                <a href='https://assets.tangentindia.org/downloads/circulars/tci_vp_nomination.pdf' target='_blank' rel='noreferrer'>Nomination Form for TCI Vice President</a>
                            </li>
                            <li>
                                <a href='https://assets.tangentindia.org/downloads/circulars/calls_for_proposals.pdf' target='_blank' rel='noreferrer'>Calls for Proposals to Changes and Amendments</a>
                            </li>
                        </ol>
                        </Col>
                        <Col>
                            <h2>AGM Related Forms</h2>
                            <ol>
                                <li>
                                    <a href='https://assets.tangentindia.org/downloads/agm/agm_delegate_form.docx' target='_blank' rel='noreferrer'>Delegate Form for Tangent India AGM</a>
                                </li>
                            </ol>
                            <h2>Drishyam Project</h2>
                            <ol>
                                <li>
                                    <a href='https://docs.google.com/forms/d/e/1FAIpQLSffAdMBME2S9LjBjEGrViW7vZVCDMdJZYq5SD46PxtPYjlYbQ/viewform' target='_blank' rel='noreferrer'>Application  form</a>
                                </li>
                            </ol>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Downloads;