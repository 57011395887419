import React from 'react';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export default class RollOfHonor extends React.Component {
    render() {
        return (
            <Row>
                {data.map((item, idx) => {
                    return (
                        <RollOfHonorItem key={idx} {...item} />
                    )
                })}
            </Row>
        );
    }
}

class RollOfHonorItem extends React.Component {
    render() {
        return (
            <Col sm={6}>
                <Table responsive striped bordered hover>
                    <thead>
                    <tr>
                        <th>{this.props.year}</th>
                        <th colSpan={2}>{this.props.theme}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.president && <tr>
                        <th>President</th>
                        <td>
                            {this.props.president}<br/>
                            {this.props.president ? '(Club No. ' + this.props.presidentClub + ')' : ''}
                        </td>
                        <td><img src={this.props.image} height='200px' alt={this.props.president}/></td>
                    </tr>}
                    {this.props.vicePresident && <tr>
                        <th>Vice President</th>
                        <td colSpan={2}>
                            {this.props.vicePresident}<br/>
                            {this.props.vicePresident ? '(Club No. ' + this.props.vicePresidentClub + ')' : ''}
                        </td>
                    </tr>}
                    {this.props.secretary && <tr>
                        <th>Secretary</th>
                        <td colSpan={2}>
                            {this.props.secretary}<br/>
                            {this.props.secretary ? '(Club No. ' + this.props.secretaryClub + ')' : ''}
                        </td>
                    </tr>}
                    {this.props.treasurer && <tr>
                        <th>Treasurer</th>
                        <td colSpan={2}>
                            {this.props.treasurer}<br/>
                            {this.props.treasurer ? '(Club No. ' + this.props.treasurerClub + ')' : ''}
                        </td>
                    </tr>}
                    {this.props.ipp && <tr>
                        <th>IPP</th>
                        <td colSpan={2}>
                            {this.props.ipp}<br/>
                            {this.props.ipp ? '(Club No. ' + this.props.ippClub + ')' : ''}
                        </td>
                    </tr>}
                    {this.props.iro && <tr>
                        <th>IRO</th>
                        <td colSpan={2}>
                            {this.props.iro}<br/>
                            {this.props.iro ? '(Club No. ' + this.props.iroClub + ')' : ''}
                        </td>
                    </tr>}
                    {this.props.webConvenor && <tr>
                        <th>Web Convenor</th>
                        <td colSpan={2}>
                            {this.props.webConvenor}<br/>
                            {this.props.webConvenor ? '(Club No. ' + this.props.webConvenorClub + ')' : ''}
                        </td>
                    </tr>}
                    </tbody>
                </Table>
            </Col>
        )
            ;
    }
}

const data = [
    {
        year: '2023-2024',
        theme: 'LET\'S BLOOM TOGETHER',
        president: 'NALINI PRASAD',
        presidentClub: '24',
        vicePresident: 'SMITA CHOPRA',
        vicePresidentClub: '18',
        secretary: 'LAKSHMI SIDDHARTHAN',
        secretaryClub: '23',
        treasurer: 'LAKSHMI SIDDHARTHAN',
        treasurerClub: '23',
        ipp: 'RANJANA SINGH',
        ippClub: '19',
        iro: 'SONAL DESAI',
        iroClub: '9',
        webConvenor: 'SHANTHI U',
        webConvenorClub: '19',
        image: 'https://assets.tangentindia.org/assets/team/2024.jpeg'
    },
    {
        year: '2022-2023',
        theme: 'BELIEVE IN ANGELS',
        president: 'RANJANA SINGH',
        presidentClub: '19',
        vicePresident: 'NALINI PRASAD',
        vicePresidentClub: '24',
        secretary: 'SMITA CHOPRA',
        secretaryClub: '18',
        treasurer: 'LAKSHMI SIDDHARTHAN',
        treasurerClub: '23',
        ipp: 'JYOTI SINGH DEO',
        ippClub: '8',
        iro: 'SIKATA DAS',
        iroClub: '8',
        webConvenor: 'ARCHANA BHAGAT',
        webConvenorClub: '4',
        image: 'https://assets.tangentindia.org/assets/team/2023.jpeg'
    },
    {
        year: '2021-2022',
        theme: 'NURTURE FRIENDSHIP NJOY HEALTH',
        president: 'JYOTI SINGH DEO',
        presidentClub: '8',
        vicePresident: 'RANJANA SINGH',
        vicePresidentClub: '19',
        secretary: 'NALINI PRASAD',
        secretaryClub: '24',
        treasurer: 'SMITA CHOPRA',
        treasurerClub: '18',
        ipp: 'JAYASHREE CHAUDHRY',
        ippClub: '15',
        iro: 'LAKSHMI SIDDHARTHAN',
        iroClub: '23',
        webConvenor: 'ARCHANA BHAGAT',
        webConvenorClub: '4',
        image: 'https://assets.tangentindia.org/assets/team/2022.jpg'
    },
    {
        year: '2020-2021',
        theme: 'To the T',
        president: 'JAYASHREE CHAUDHRY',
        presidentClub: '15',
        vicePresident: 'JYOTI SINGH DEO',
        vicePresidentClub: '8',
        secretary: 'RANJANA SINGH',
        secretaryClub: '19',
        treasurer: 'NALINI PRASAD',
        treasurerClub: '24',
        ipp: 'BIJAL KALBAG',
        ippClub: '1',
        iro: 'SMITA CHOPRA',
        iroClub: '18',
        webConvenor: 'SIKATA DAS',
        webConvenorClub: '8',
        image: 'https://assets.tangentindia.org/assets/team/2021.jpeg'
    },
    {
        year: '2019-2020',
        theme: 'She Unlimited',
        president: 'BIJAL KALBAG',
        presidentClub: '1',
        vicePresident: 'JAYASHREE CHAUDHRY',
        vicePresidentClub: '15',
        secretary: 'JYOTI SINGH DEO',
        secretaryClub: '8',
        treasurer: 'RANJANA SINGH',
        treasurerClub: '19',
        ipp: 'SUKANYA L. NARAYAN',
        ippClub: '10',
        iro: '',
        iroClub: '',
        webConvenor: 'RANJANA SINGH & SMITA CHOPRA',
        webConvenorClub: '19 & 18',
        image: 'https://assets.tangentindia.org/assets/team/1920.jpeg'
    },
    {
        theme: 'CELEBRATE FRIENDSHIP',
        year: '2018-2019',
        president: 'SUKANYA L. NARAYAN',
        presidentClub: '10',
        vicePresident: 'BIJAL KALBAG',
        vicePresidentClub: '1',
        secretary: 'JAYASHREE CHAUDHRY',
        secretaryClub: '15',
        treasurer: 'JYOTI SINGH DEO',
        treasurerClub: '8',
        ipp: 'RENUKA SEHGAL',
        ippClub: '3',
        iro: '',
        iroClub: '',
        webConvenor: 'RANJANA SINGH',
        webConvenorClub: '19',
        image: 'https://assets.tangentindia.org/assets/team/1819.png'
    }, {
        theme: 'BONDS BEYOND BOUNDARIES',
        year: '2017-2018',
        president: 'RENUKA SEHGAL',
        presidentClub: '3',
        vicePresident: 'SUKANYA L NARAYAN',
        vicePresidentClub: '10',
        secretary: 'BIJAL KALBAG',
        secretaryClub: '1',
        treasurer: 'ALKA ARREN',
        treasurerClub: '3',
        iro: '',
        iroClub: '',
        ipp: 'KALPANA UDAYKUMAR',
        ippClub: '10',
        image: 'https://assets.tangentindia.org/assets/team/1718.png'
    }, {
        theme: 'EVERGREEN TANGENTS – Care, Share,Contribute',
        year: '2016-2017',
        president: 'KALPANA UDAYKUMAR',
        presidentClub: '10',
        vicePresident: 'RENUKA SEHGAL',
        vicePresidentClub: '3',
        secretary: 'SUKANYA LAKSHMINARAYAN',
        secretaryClub: '10',
        treasurer: 'RENU NANDA',
        treasurerClub: '13',
        ipp: 'JAYSHREE K THAPAR',
        ippClub: '3',
        iro: '',
        iroClub: '',
        image: 'https://assets.tangentindia.org/assets/team/1617.png'
    }, {
        theme: 'MAKE A MARK',
        year: '2015-2016',
        president: 'JAYSHREE K THAPAR',
        presidentClub: '3',
        vicePresident: 'KALPANA UDAYKUMAR',
        vicePresidentClub: '10',
        secretary: 'RENUKA SEHGAL',
        secretaryClub: '3',
        treasurer: 'MEENA SHARMA',
        treasurerClub: '10',
        ipp: 'PERIN POMBRA',
        ippClub: '7',
        iro: '',
        iroClub: '',
        image: 'https://assets.tangentindia.org/assets/team/1516.png'
    }, {
        theme: 'FIND YOUR TANGENT',
        year: '2014-2015',
        president: 'PERIN POMBRA',
        presidentClub: '7',
        vicePresident: 'JAYASHREE K THAPAR',
        vicePresidentClub: '3',
        secretary: 'KALPANA UDAYKUMAR',
        secretaryClub: '10',
        treasurer: 'RENUKA SEHGAL',
        treasurerClub: '3',
        ipp: 'MAMTA BHARGAVA',
        ippClub: '3',
        iro: '',
        iroClub: '',
        image: 'https://assets.tangentindia.org/assets/team/1415.png'
    }, {
        theme: 'EMBRACE & EXPAND',
        year: '2013-2014',
        president: 'MAMTA BHARGAVA',
        presidentClub: '3',
        vicePresident: 'PERIN POMBRA',
        vicePresidentClub: '7',
        secretary: 'JAYSHREE K THAPAR',
        secretaryClub: '3',
        treasurer: 'LATA PALL',
        treasurerClub: '3',
        ipp: 'MINI KAR',
        ippClub: '8',
        image: 'https://assets.tangentindia.org/assets/team/1314.png'
    }, {
        theme: 'SOW THE SEEDS OF FRIENDSHIP',
        year: '2012-2013',
        president: 'MINI KAR',
        presidentClub: '8',
        vicePresident: 'MAMTA BHARGAVA',
        vicePresidentClub: '3',
        secretary: 'PERIN POMBRA',
        secretaryClub: '7',
        treasurer: 'JAYSHREE K THAPAR',
        treasurerClub: '3',
        ipp: 'ARUNA UMMAT',
        ippClub: '3',
        image: 'https://assets.tangentindia.org/assets/team/1213.png'
    }, {
        theme: 'CHERISH FRIENDSHIP',
        year: '2011-2012',
        president: 'ARUNA UMMAT',
        presidentClub: '3',
        vicePresident: 'MINI KAR',
        vicePresidentClub: '8',
        secretary: 'PAWAN JAIN',
        secretaryClub: '2',
        treasurer: 'MAMTA BHARGAVA',
        treasurerClub: '3',
        ipp: 'REETI ROY',
        ippClub: '6',
        image: 'https://assets.tangentindia.org/assets/team/1112.png'
    }, {
        theme: 'FRIENDS FOREVER',
        year: '2010-2011',
        president: 'REETI ROY',
        presidentClub: '6',
        vicePresident: 'ARUNA UMMAT',
        vicePresidentClub: '3',
        secretary: 'BHARTI CHOWDHURY',
        secretaryClub: '6',
        treasurer: 'MAMTA BHARGAVA',
        treasurerClub: '3',
        ipp: 'RACHNA JAIN',
        ippClub: '3',
        image: 'https://assets.tangentindia.org/assets/team/1011.png'
    },
    {
        year: '2009-2010',
        president: 'RACHNA JAIN',
        presidentClub: '3',
        vicePresident: 'REETI ROY',
        vicePresidentClub: '6',
        secretary: 'MAMTA BHARGAVA',
        secretaryClub: '3',
        treasurer: 'MAMTA BHARGAVA',
        treasurerClub: '3',
        ipp: 'JOGESH MITTAL',
        ippClub: '1',
        iro: '',
        iroClub: '',
        webConvenor: '',
        webConvenorClub: '',
        image: 'https://assets.tangentindia.org/assets/team/0910.png'
    },
    {
        year: '2006-2009',
        president: 'JOGESH MITTAL',
        presidentClub: '1',
        vicePresident: '',
        vicePresidentClub: '',
        secretary: 'PAWAN JAIN',
        secretaryClub: '2',
        treasurer: '',
        treasurerClub: '',
        ipp: '',
        ippClub: '',
        iro: '',
        iroClub: '',
        webConvenor: '',
        webConvenorClub: '',
        image: 'https://assets.tangentindia.org/assets/team/0609.png'
    },
]