import React from "react";
import Container from "react-bootstrap/Container";

function PageNotFound() {
    const sectionStyle = {
        backgroundImage: "url('https://assets.tangentindia.org/assets/home_banner_back.webp')"
    };
    return (
        <div id="documents">
            {/*<div id="home-header-background" style={sectionStyle}>*/}
            {/*    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'*/}
            {/*         className="hidden header-foreground" alt=""/>*/}
            {/*</div>*/}
            <div className="center title">
                <h1>Page Not Found</h1>
            </div>
            <div className="center section">
                <Container>
                    <strong>
                        Oops! Looks like you lost your way. Our apologies!
                    </strong>
                </Container>
            </div>
        </div>
    );
}

export default PageNotFound;

