import React from "react";
import Container from 'react-bootstrap/Container';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleRight} from "@fortawesome/free-solid-svg-icons";
import Seo from "../../Seo";
import {Link} from "react-router-dom";

function Membership() {
    const sectionStyle = {
        backgroundImage: "url('https://assets.tangentindia.org/assets/home_banner_back.webp')"
    };
    return (
        <div id="membership">
            <Seo title='Membership | Tangent India'
                 description="Information about Tangent India's Membership Policies, Membership Criteria and Membership Procedure. Also includes the Membership Form"
            />
            {/*<div id="home-header-background" style={sectionStyle}>*/}
            {/*    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'*/}
            {/*         className="hidden header-foreground" alt=""/>*/}
            {/*</div>*/}
            <div className="title center">
                <h1>
                    Tangent India Membership
                </h1>
            </div>
            <div>
                <Container className="section">
                    <h2>Membership Criteria</h2>

                    <ul className="fa-ul">
                        {
                            [
                                "Members of Tangent India comprise past Circlers, ex- Tablers’ wives and wives of 41ers",
                                "A member can continue to be a member of Tangent India, even if her spouse ceases to be a member of 41 Club due to any reason.",
                                "A minimum of 6 members are required for the formation of a new club",
                                "A minimum of 3 contact meetings of prospective members must be held prior to chartering of a club",
                                "A prospective member should be invited to attend 3 meetings, before she is inducted as a member of the club"
                            ].map((item, idx) => {
                                return (
                                    <li key={idx} className="small-objective">
                                        <FontAwesomeIcon key={idx} icon={faArrowCircleRight} listItem/>
                                        {item}
                                    </li>
                                );
                            })
                        }
                    </ul>
                    <Link to='/new-membership'>Click here to fill the New Membership Online Registration Form.</Link>
                </Container>
            </div>
        </div>
    );
}

export default Membership;