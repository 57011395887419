import React from "react";
import {NavLink} from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faYoutube} from "@fortawesome/free-brands-svg-icons";
import NavDropdown from "react-bootstrap/NavDropdown";
import LoginButton from "../Admin/LoginButton";
import {BsPerson} from "react-icons/bs";
import Button from "react-bootstrap/Button";

export default class Navigation extends React.Component {
    render() {
        return (
            <div>

                <Navbar expand="lg" sticky="top" className="bg-white">
                    <Container>
                        <Navbar.Brand href="/">
                            <img
                                alt="Tangent India Logo"
                                src='https://assets.tangentindia.org/assets/national-logo.jpg'
                                height='100px'
                                className="d-inline-block align-top"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav>
                                <Nav.Link as={NavLink} to="/">Home</Nav.Link>
                                <Nav.Link as={NavLink} to="/about-us">About Us</Nav.Link>
                                <Nav.Link as={NavLink} to="/meet-the-team">Meet the Team</Nav.Link>
                                {/*<Dropdown title='About Us' menuItems={[{title: 'About Us', url: '/about-us'}, {*/}
                                {/*    title: 'Meet the Team',*/}
                                {/*    url: '/meet-the-team'*/}
                                {/*}]}/>*/}
                                {/*<Nav.Link as={NavLink} to="/documents">Documents</Nav.Link>*/}
                                {/*<Dropdown title='New Memberships and Clubs'*/}
                                {/*          menuItems={[{title: 'Membership', url: '/membership'}, {*/}
                                {/*              title: 'Extensions',*/}
                                {/*              url: '/extensions'*/}
                                {/*          }]}/>*/}
                                <Nav.Link as={NavLink} to="/membership">Membership</Nav.Link>
                                <Nav.Link as={NavLink} to="/extensions">Extensions</Nav.Link>
                                {/*<Nav.Link as={NavLink} to="/members">Members Area</Nav.Link>*/}
                                <Nav.Link as={NavLink} to="/projects">Projects</Nav.Link>
                                <Nav.Link as={NavLink} to="/business">Business Network</Nav.Link>
                                <Nav.Link as={NavLink} to="/events">Events</Nav.Link>
                                <Nav.Link as={NavLink} to="/publications">Publications</Nav.Link>
                                <Nav.Link as={NavLink} to="/contact-us">Contact Us</Nav.Link>
                                {/*<Nav.Link as={NavLink} to="/admin"><BsPerson /></Nav.Link>*/}
                                {/*<Button variant="outline-secondary" onClick={() => loginWithRedirect()}><BsPerson /></Button>*/}
                                {/*<LoginButton />*/}
                                {/*<Nav.Link as={NavLink} to="/admin"><BsPerson /></Nav.Link>*/}
                                {/*<Button variant="outline-secondary"as={NavLink} to="/admin">Admin Page</Button>*/}
                                {/*{isLoggedIn() && <LogoutButton/>}*/}
                            </Nav>

                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}

class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
        this.mouseLeave = this.mouseLeave.bind(this)
        this.mouseEnter = this.mouseEnter.bind(this);
    }

    mouseLeave(e) {
        this.setState({
            ...this.state,
            show: false
        })
    }

    mouseEnter(e) {
        this.setState({
            ...this.state,
            show: true
        })
    }

    render() {
        return (
            <NavDropdown
                id="nav-dropdown-dark-example"
                title={this.props.title}
                menuVariant="dark"
                onMouseLeave={this.mouseLeave}
                onMouseEnter={this.mouseEnter}
                show={this.state.show}
            >
                {this.props.menuItems.map((menuItem, idx) => {
                    return (
                        <NavDropdown.Item key={idx} as={NavLink} to={menuItem.url}>{menuItem.title}</NavDropdown.Item>
                    );
                })}
            </NavDropdown>
        )
    }
}