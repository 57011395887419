import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Seo from "../../Seo";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Alert from "react-bootstrap/Alert";
import {getUser} from './utils';
import ClubScreen from "./ClubScreen";
import Spinner from "react-bootstrap/Spinner";
import {PageNotFound} from "../index";

const year = 2022;

export default class AAF extends React.Component {
    constructor(props) {
        super(props);
        var stateVal = {
            validated: false,
            memberCount: 5,
            members: [],
            amigoForm: false,
            editForm: false,
            initLoading: false
        };
        if (window.location.pathname.startsWith('/admin/annual-administrative-fee-form/edit/amigo')) {
            stateVal = {
                validated: false,
                memberCount: 5,
                members: [],
                amigoForm: true,
                editForm: true,
                editType: 'amigo',
                initLoading: true
            }
        }
        if (window.location.pathname.startsWith('/admin/annual-administrative-fee-form/edit/club')) {
            stateVal = {
                validated: false,
                memberCount: 5,
                members: [],
                amigoForm: false,
                editForm: true,
                editType: 'club',
                initLoading: true
            }
        }
        this.state = stateVal;
        this.changeState = this.changeState.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeMemberState = this.changeMemberState.bind(this);
        this.changeMemberCount = this.changeMemberCount.bind(this);
        this.changeAmigoForm = this.changeAmigoForm.bind(this);
    }

    componentDidMount() {
        if (!this.state.user) {
            this.setState({
                ...this.state,
                user: getUser()
            });
        }

        if (window.location.pathname.startsWith('/admin/annual-administrative-fee-form/edit/amigo')) {
            fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/admin/amigo?name=' + encodeURIComponent(window.location.pathname.split('/admin/annual-administrative-fee-form/edit/amigo/')[1]) + '&userId=' + encodeURIComponent(getUser()['sub']))
                .then(response => response.json())
                .then(response => {
                    if (response.length === 0) {
                        this.setState({
                            ...this.state,
                            notFound: true,
                        })
                    }
                    this.setState({
                        ...this.state,
                        amigoName: response[0]['name']['S'],
                        amigoAddress: response[0]['address']['S'],
                        amigoPhone: response[0]['phone']['S'],
                        amigoEmail: response[0]['email']['S'],
                        amigoDob: response[0]['dob']['S'],
                        initLoading: false,
                        validated: true
                    })
                })
        }

        if (window.location.pathname.startsWith('/admin/annual-administrative-fee-form/edit/club')) {
            const clubNUm = window.location.pathname.split('/admin/annual-administrative-fee-form/edit/club/')[1];
            fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/admin/club?clubNum=' + clubNUm + '&userId=' + encodeURIComponent(getUser()['sub']))
                .then(response => response.json())
                .then(response => {
                    if (response.length === 0) {
                        this.setState({
                            ...this.state,
                            notFound: true
                        })
                    }
                    this.setState({
                        ...this.state,
                        chairpersonName: response[0]['chairpersonName']['S'],
                        chairpersonEmail: response[0]['chairpersonEmail']['S'],
                        chairpersonPhone: response[0]['chairpersonPhone']['S'],
                        chairpersonDob: response[0]['chairpersonDob']['S'],
                        chairpersonAddress: response[0]['chairpersonAddress']['S'],
                        secretaryName: response[0]['secretaryName']['S'],
                        secretaryEmail: response[0]['secretaryEmail']['S'],
                        secretaryPhone: response[0]['secretaryPhone']['S'],
                        secretaryDob: response[0]['secretaryDob']['S'],
                        secretaryAddress: response[0]['secretaryAddress']['S'],
                        members: JSON.parse(response[0]['members']['S']),
                        city: response[0]['city']['S'],
                        clubNum: response[0]['clubNum']['S'],
                        memberCount: JSON.parse(response[0]['members']['S']).length + (this.state.editForm ? 2 : 0),
                        initLoading: false,
                        validated: true
                    })
                })

        }
    }

    changeMemberCount(e) {
        if (e.target.value < 5) {
            return;
        }
        this.setState({
            ...this.state,
            memberCount: e.target.value
        })
    }

    changeMemberState(e) {
        var field = '';
        var idx = -1;
        if (e.target.name.startsWith('memberName')) {
            field = 'name';
            idx = e.target.name.split('memberName')[1];
        } else if (e.target.name.startsWith('memberEmail')) {
            field = 'email';
            idx = e.target.name.split('memberEmail')[1];
        } else if (e.target.name.startsWith('memberPhone')) {
            field = 'phone';
            idx = e.target.name.split('memberPhone')[1];
        } else if (e.target.name.startsWith('memberDob')) {
            field = 'dob';
            idx = e.target.name.split('memberDob')[1];
        } else if (e.target.name.startsWith('memberAddress')) {
            field = 'address';
            idx = e.target.name.split('memberAddress')[1];
        }
        var updatedMembers = this.state.members;
        if (!updatedMembers[idx]) {
            updatedMembers[idx] = {}
        }
        updatedMembers[idx][field] = e.target.value;
        this.setState({
            ...this.state,
            members: updatedMembers
        });
    }

    changeState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();

        this.setState({
            validated: true
        })

        if (form.checkValidity() === false) {
            event.stopPropagation();
            return;
        }

        this.setState({
            loading: true
        })

        if (this.state.editForm) {
            fetch(this.state.editType === 'amigo' ? 'https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/admin/amigo?userId=' + encodeURIComponent(getUser()['sub']) : 'https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/admin/club?userId=' + encodeURIComponent(getUser()['sub']), {
                method: 'PATCH',
                body: JSON.stringify(this.state)
            })
                .then(response => response.json())
                .then(response => {
                    this.setState({
                        ...this.state,
                        alert: true,
                        alertMessage: response['message'],
                        alertType: response['type'],
                        loading: false,
                        // name: '',
                        // email: '',
                        // message: ''
                    });
                })



        } else {
            fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/aafForm', {
                method: 'POST',
                body: JSON.stringify(this.state)
            })
                .then(response => response.json())
                .then(response => {
                    this.setState({
                        ...this.state,
                        alert: true,
                        alertMessage: response['message'],
                        alertType: response['type'],
                        loading: false,
                        // name: '',
                        // email: '',
                        // message: ''
                    });
                })
        }
    }

    changeAmigoForm(e) {
        this.setState({
            ...this.state,
            amigoForm: !this.state.amigoForm
        })
    }

    render() {

        console.log(this.state.memberCount)

        if (this.state.notFound) {
            return <PageNotFound />
        }

        const sectionStyle = {
            backgroundImage: "url('https://assets.tangentindia.org/assets/home_banner_back.webp')"
        };
        return (
            <div id="annual-administrative-fee-form">
                <Seo title='Annual Administrative Fee Form | Tangent India'
                     description='Administrative Form to be filled by Tangent India Clubs regarding the Annual Administrative Fee.'
                     noindex={true}
                />
                {/*<div id="home-header-background" style={sectionStyle}>*/}
                {/*    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'*/}
                {/*         className="hidden header-foreground" alt=""/>*/}
                {/*</div>*/}
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin">Admin</Breadcrumb.Item>
                    <Breadcrumb.Item active>Annual Administrative Fee Form</Breadcrumb.Item>
                </Breadcrumb>
                <ClubScreen/>
                <div className="center title">
                    <h1>Annual Administrative Fee Form</h1>
                </div>
                <div className="section">
                    {this.state.initLoading && <div className='center'><Spinner className='center' animation="border"/></div>}
                    <Container>
                        {this.state.alertType ?
                            <Alert variant={this.state.alertType}>{this.state.alertMessage}</Alert> : ''}
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            {this.state.editForm ||
                                <Row>
                                    <Col>
                                        {/*<Form.Label>Form is being filled by an Amigo</Form.Label>*/}
                                        <Form.Check
                                            type='checkbox'
                                            label='Form is being filled by an Amigo'
                                            checked={this.state.amigoForm}
                                            onChange={this.changeAmigoForm}
                                        />
                                    </Col>
                                </Row>
                            }
                            {!this.state.amigoForm ? <div>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formClubNum">
                                                <Form.Label>Club Number</Form.Label>
                                                <Form.Control required type="number" placeholder="Enter club number"
                                                              value={this.state.clubNum}
                                                              name='clubNum'
                                                              onChange={this.changeState}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formCity">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control required type="input" placeholder="Enter city"
                                                              value={this.state.city}
                                                              name='city'
                                                              onChange={this.changeState}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formMemberCount">
                                                <Form.Label>Number of members (including Chairperson and
                                                    Secretary)</Form.Label>
                                                <Form.Control required type="number" placeholder="Enter number of members"
                                                              value={this.state.memberCount}
                                                              name='memberCount'
                                                              onChange={this.changeMemberCount}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formChairpersonName">
                                                <Form.Label>Name of Chairperson</Form.Label>
                                                <Form.Control required type="input" placeholder="Enter name"
                                                              value={this.state.chairpersonName}
                                                              name='chairpersonName'
                                                              onChange={this.changeState}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formChairpersonEmail">
                                                <Form.Label>Chairperson's Email</Form.Label>
                                                <Form.Control required type="email" placeholder="Enter email"
                                                              value={this.state.chairpersonEmail}
                                                              name='chairpersonEmail'
                                                              onChange={this.changeState}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formChairpersonEmail">
                                                <Form.Label>Chairperson's Phone</Form.Label>
                                                <Form.Control required type="number" placeholder="Enter phone"
                                                              value={this.state.chairpersonPhone}
                                                              name='chairpersonPhone'
                                                              onChange={this.changeState}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formChairpersonDob">
                                                <Form.Label>Chairperson's Date of Birth</Form.Label>
                                                <Form.Control required type="date"
                                                              value={this.state.chairpersonDob}
                                                              max={1000 + '-12-31'}
                                                              min={1000 + '-01-01'}
                                                              name='chairpersonDob'
                                                              onChange={this.changeState}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formChairpersonAddress">
                                                <Form.Label>Chairperson's Address</Form.Label>
                                                <Form.Control required type="input" placeholder="Enter address"
                                                              value={this.state.chairpersonAddress}
                                                              name='chairpersonAddress'
                                                              onChange={this.changeState}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formSecretaryName">
                                                <Form.Label>Name of Secretary</Form.Label>
                                                <Form.Control required type="input" placeholder="Enter name"
                                                              value={this.state.secretaryName}
                                                              name='secretaryName'
                                                              onChange={this.changeState}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formSecretaryEmail">
                                                <Form.Label>Secretary's Email</Form.Label>
                                                <Form.Control required type="email" placeholder="Enter email"
                                                              value={this.state.secretaryEmail}
                                                              name='secretaryEmail'
                                                              onChange={this.changeState}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formSecretaryPhone">
                                                <Form.Label>Secretary's Phone</Form.Label>
                                                <Form.Control required type="input" placeholder="Enter phone"
                                                              value={this.state.secretaryPhone}
                                                              name='secretaryPhone'
                                                              onChange={this.changeState}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formSecretaryDob">
                                                <Form.Label>Secretary's Date of Birth</Form.Label>
                                                <Form.Control required type="date"
                                                              max={1000 + '-12-31'}
                                                              min={1000 + '-01-01'}
                                                              value={this.state.secretaryDob}
                                                              name='secretaryDob'
                                                              onChange={this.changeState}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formSecretaryAddress">
                                                <Form.Label>Secretary's Address</Form.Label>
                                                <Form.Control required type="input" placeholder="Enter address"
                                                              value={this.state.secretaryAddress}
                                                              name='secretaryAddress'
                                                              onChange={this.changeState}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {Array.from({length: this.state.memberCount - 2}, (v, i) => i).map(idx => {
                                                return (
                                                    <Row key={idx}>
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId={"formMemberName" + idx}>
                                                                <Form.Label>Member Name</Form.Label>
                                                                <Form.Control required type="input"
                                                                              placeholder="Enter name"
                                                                              value={this.state.members[idx] ? this.state.members[idx]['name'] : ''}
                                                                              name={'memberName' + idx}
                                                                              onChange={this.changeMemberState}/>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3"
                                                                        controlId={"formMemberEmail" + idx}>
                                                                <Form.Label>Member Email</Form.Label>
                                                                <Form.Control required type="email"
                                                                              placeholder="Enter email"
                                                                              value={this.state.members[idx] ? this.state.members[idx]['email'] : ''}
                                                                              name={'memberEmail' + idx}
                                                                              onChange={this.changeMemberState}/>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3"
                                                                        controlId={"formMemberPhone" + idx}>
                                                                <Form.Label>Member Phone</Form.Label>
                                                                <Form.Control required type="number"
                                                                              placeholder="Enter phone"
                                                                              value={this.state.members[idx] ? this.state.members[idx]['phone'] : ''}
                                                                              name={'memberPhone' + idx}
                                                                              onChange={this.changeMemberState}/>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId={"formDob" + idx}>
                                                                <Form.Label>Date of Birth</Form.Label>
                                                                <Form.Control required type="date"
                                                                              max={1000 + '-12-31'}
                                                                              min={1000 + '-01-01'}
                                                                              value={this.state.members[idx] ? this.state.members[idx]['dob'] : ''}
                                                                              name={'memberDob' + idx}
                                                                              onChange={this.changeMemberState}/>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3"
                                                                        controlId={"formMemberAddress" + idx}>
                                                                <Form.Label>Member Address</Form.Label>
                                                                <Form.Control required type="input"
                                                                              placeholder="Enter address"
                                                                              value={this.state.members[idx] ? this.state.members[idx]['address'] : ''}
                                                                              name={'memberAddress' + idx}
                                                                              onChange={this.changeMemberState}/>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                        </Col>
                                    </Row>
                                    {this.state.editForm ||
                                    <div>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formMemberCount">
                                                    <Form.Label>Number of Members as on 31st
                                                        December {year - 1}</Form.Label>
                                                    <Form.Control required type="number"
                                                                  placeholder="Enter number of members as on 31st december"
                                                                  value={this.state.memberCount31July}
                                                                  name='memberCount31July'
                                                                  onChange={this.changeState}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formMembersLeft">
                                                    <Form.Label>Number of Members who have left between 1st
                                                        January {year - 1} and 31st
                                                        December {year - 1}</Form.Label>
                                                    <Form.Control required type="number"
                                                                  value={this.state.membersLeft}
                                                                  name='membersLeft'
                                                                  onChange={this.changeState}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formMembersJoined">
                                                    <Form.Label>Number of Members who have joined between 1st
                                                        January {year - 1} and 31st
                                                        December {year - 1}</Form.Label>
                                                    <Form.Control required type="number"
                                                                  value={this.state.membersJoined}
                                                                  name='membersJoined'
                                                                  onChange={this.changeState}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                    }
                                </div> :
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formAmigoName">
                                            <Form.Label>Amigo Name</Form.Label>
                                            <Form.Control required type="input"
                                                          placeholder="Enter name"
                                                          value={this.state.amigoName}
                                                          name='amigoName'
                                                          onChange={this.changeState}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3"
                                                    controlId="formAmigoEmail">
                                            <Form.Label>Amigo Email</Form.Label>
                                            <Form.Control required type="email"
                                                          placeholder="Enter email"
                                                          value={this.state.amigoEmail}
                                                          name='amigoEmail'
                                                          onChange={this.changeState}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3"
                                                    controlId="formAmigoPhone">
                                            <Form.Label>Amigo Phone</Form.Label>
                                            <Form.Control required type="number"
                                                          placeholder="Enter phone"
                                                          value={this.state.amigoPhone}
                                                          name='amigoPhone'
                                                          onChange={this.changeState}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formAmigoDob">
                                            <Form.Label>Date of Birth</Form.Label>
                                            <Form.Control required type="date"
                                                          max={1000 + '-12-31'}
                                                          min={1000 + '-01-01'}
                                                          value={this.state.amigoDob}
                                                          name='amigoDob'
                                                          onChange={this.changeState}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3"
                                                    controlId="formAmigoAddress">
                                            <Form.Label>Amigo Address</Form.Label>
                                            <Form.Control required type="input"
                                                          placeholder="Enter address"
                                                          value={this.state.amigoAddress}
                                                          name='amigoAddress'
                                                          onChange={this.changeState}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            }
                            {this.state.editForm ||
                            <div>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formPaymentDue">
                                            <Form.Label>Annual Payment Due</Form.Label>
                                            <Form.Control required type="number"
                                                          value={this.state.paymentDue}
                                                          name='paymentDue'
                                                          onChange={this.changeState}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formTransactionId">
                                            <Form.Label>Transaction Id for Payment Made</Form.Label>
                                            <Form.Control required type="input"
                                                          value={this.state.transactionId}
                                                          name='transactionId'
                                                          onChange={this.changeState}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>}
                            <Row>
                                {this.state.alertType ?
                                    <Alert variant={this.state.alertType}>{this.state.alertMessage}</Alert> : ''}
                                {this.state.loading && <Spinner animation="border"/>}
                            </Row>
                            <Row>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Row>
                        </Form>
                    </Container>
                </div>
            </div>
        );
    }
}
